export enum Permissions {
  ASSETS = "assets",
  COMPANY_ADMIN = "company.admin",
  GRAPHIC_VIEW = "analytics.graphics.view",
  ALARMS_VIEW = "analytics.alarms.view",
  HISTORY_VIEW = "analytics.history.view",
  POINTS_VIEW = "analytics.points.view",
  EVENTLOG_VIEW = "analytics.eventlog.view",
  ALARMLOG_VIEW = "analytics.alarmlog.view",
  DEVICES_VIEW = "analytics.devices.view",
  CONVERSIONTABLE_EDIT = "analytics.conversiontable.edit",
  TIMESCHEDULE_EDIT = "analytics.timeschedule.edit",
  ALARM_EDIT = "analytics.alarm.edit",
  POINTSTATE_EDIT = "analytics.pointstate.edit",
  POINTGROUPS_EDIT = "analytics.pointgroups.edit",
  SCADAGRAPHICS_EDIT = "analytics.scadagraphics.edit",
  CALENDARS_VIEW = "analytics.calendars.view",
  CALENDARS_EDIT = "analytics.calendars.edit",
}
